import React, {useCallback, useEffect, useState} from "react";
import * as apiService from "../backend/apiService";
import {
    CityResponse,
    PageableCityArtistResponse,
    PageableEventResponse,
    PageableVenueResponse
} from "../backend/apiService";
import {useNavigate, useParams} from "react-router-dom";
import styles from "../style/pages/CityDetail.module.scss";
import PageHeader, {HeaderTypes} from "../components/PageHeader";
import ListOfCitiesModal from "../components/ListOfCitiesModal";
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import * as stringFormatting from "../utils/StringFormatting";
import CircularProgress from '@mui/material/CircularProgress';
import CityIcon from "../assets/CityIcon.png";
import ErrorModal from "../components/ErrorModal";
import {Assessment, Feedback, GroupAdd, Map} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import AddArtistsToCityModal from "../components/AddArtistsToCityModal";
import {userHasToken} from "../backend/loginApi";

enum filters {
    ARTISTS = "Artists",
    EVENTS = "Upcoming Events",
    VENUES = "Venues"
}

enum ErrorTypes {
    NO_ID_PRESENT = "No ID present",
    FAILED_TO_FETCH = "Failed to fetch",
    FAILED_TO_FETCH_ADDITIONAL_DETAILS = "Failed to fetch additional details"
}

const CityDetail: React.FC = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const userLoggedIn = userHasToken();

    const [city, setCity] = useState<CityResponse | undefined>();
    const [showEditCitiesModal, setShowEditCitiesModal] = useState<boolean>(false);
    const [showAddArtistsModal, setShowAddArtistsModal] = useState<boolean>(false);
    const [currentFilter, setCurrentFilter] = useState<filters>(filters.ARTISTS);

    const [artists, setArtists] = useState<PageableCityArtistResponse | undefined>();
    const [events, setEvents] = useState<PageableEventResponse | undefined>();
    const [venues, setVenues] = useState<PageableVenueResponse | undefined>();

    const [artistPageNum, setArtistPageNum] = useState<number>(0);
    const [eventPageNum, setEventPageNum] = useState<number>(0);
    const [venuePageNum, setVenuePageNum] = useState<number>(0);
    const [showButtonLoading, setShowButtonLoading] = useState<boolean>(false);

    const [errorType, setErrorType] = useState<ErrorTypes | undefined>();

    const noImage = 'https://localify-cdn.s3.amazonaws.com/assets/web_assets/NoImage.jpeg';

    useEffect(() => {
        if (id) {
            fetchData(id).then();
        } else {
            setErrorType(ErrorTypes.NO_ID_PRESENT);
        }
    }, [id]);

    const fetchData = async (id: string) => {
        try {
            const [cityDetails, artists, events, venues] = await Promise.all([
                apiService.fetchCityDetails(id),
                apiService.fetchArtistsForCities(id),
                apiService.fetchEventsForCities(id),
                apiService.fetchVenuesForCities(id)
            ]);

            setCity(cityDetails);
            setArtists(artists);
            setEvents(events);
            setVenues(venues);
            setErrorType(undefined);
        } catch (error) {
            setErrorType(ErrorTypes.FAILED_TO_FETCH);
        }
    };

    const handleShowMore = async () => {
        setShowButtonLoading(true);
        try {
            if (city) {
                if (currentFilter === filters.ARTISTS) {
                    const nextPageNum = artistPageNum + 1;
                    const newArtists = await apiService.fetchArtistsForCities(city.id, {page: nextPageNum});
                    if (newArtists?.content && newArtists.content.length > 0) {
                        setArtists(prevArtists => {
                            if (!prevArtists) {
                                return newArtists;
                            }
                            return {
                                ...newArtists,
                                content: [...prevArtists.content, ...newArtists.content]
                            };
                        });
                        setArtistPageNum(nextPageNum);
                    }
                } else if (currentFilter === filters.EVENTS) {
                    const nextPageNum = eventPageNum + 1;
                    const newEvents = await apiService.fetchEventsForCities(city.id, {page: nextPageNum});
                    if (newEvents?.content && newEvents.content.length > 0) {
                        setEvents(prevEvents => {
                            if (!prevEvents) {
                                return newEvents;
                            }
                            return {
                                ...newEvents,
                                content: [...prevEvents.content, ...newEvents.content]
                            };
                        });
                        setEventPageNum(nextPageNum);
                    }
                } else if (currentFilter === filters.VENUES) {
                    const nextPageNum = venuePageNum + 1;
                    const newVenues = await apiService.fetchVenuesForCities(city.id, {page: nextPageNum});
                    if (newVenues?.content && newVenues.content.length > 0) {
                        setVenues(prevVenues => {
                            if (!prevVenues) {
                                return newVenues;
                            }
                            return {
                                ...newVenues,
                                content: [...prevVenues.content, ...newVenues.content]
                            };
                        });
                        setVenuePageNum(nextPageNum);
                    }
                }
            }

            setErrorType(undefined);
        } catch (error) {
            setErrorType(ErrorTypes.FAILED_TO_FETCH_ADDITIONAL_DETAILS);
        } finally {
            setShowButtonLoading(false);
        }
    };

    const handleGetPersonalRecs = useCallback(() => {
        if (city) {
            console.log("City found:", city);
            if (city.isUserLocalCity) {
                console.log("Navigating to recommendations");
                apiService.patchUserCities(city.id, true).then(() => navigate("/recommendations"));
            } else {
                console.log("Navigating to edit radius page");
                apiService.putUserCity(city.id, 5).then(() => navigate(`/edit-radius/location?id=${city.id}&radius=5`));
            }
        } else {
            console.log("No city found");
        }
    }, [city, navigate]);


    const fetchErrorMessage = () => {
        if (errorType === ErrorTypes.NO_ID_PRESENT)
            return "The city you're looking for couldn't be found.";
        else if (errorType === ErrorTypes.FAILED_TO_FETCH)
            return "Failed to fetch City Details.";
        else if (errorType === ErrorTypes.FAILED_TO_FETCH_ADDITIONAL_DETAILS)
            return `Failed to fetch additional ${currentFilter}`;
    }

    const handleRetry = () => {
        if (errorType === ErrorTypes.FAILED_TO_FETCH)
            if (id) {
                fetchData(id).then();
            } else {
                setErrorType(ErrorTypes.NO_ID_PRESENT);
            }
        else if (errorType === ErrorTypes.FAILED_TO_FETCH_ADDITIONAL_DETAILS)
            handleShowMore().then();
    }

    const renderContent = () => {
        if (currentFilter === filters.ARTISTS) {
            if (artists === undefined || artists.content === undefined) {
                return <div className={styles["loading-container"]}><CircularProgress
                    className={styles["progress-circle"]}/></div>;
            } else if (artists.content.length === 0) {
                return <div className={styles["no-content"]}><h6>City has no artists.</h6></div>;
            } else {
                return (
                    <>
                        <div className={styles["artists-grid-wrapper"]}>
                            <div className={styles["artists-grid"]}>
                                {artists.content.map((artist, index) => (
                                    <div
                                        key={index}
                                        className={styles["artist-card"]}
                                        onClick={() => navigate(`/artist/${artist.id}/${stringFormatting.toSlug(artist.name)}`)}
                                    >
                                        <img
                                            src={artist.image ? artist.image : noImage}
                                            alt={artist.name}
                                            className={styles["artist-image"]}
                                        />
                                        <h6 className={styles["artist-name"]}>{artist.name}</h6>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {artists.totalPages > (artistPageNum + 1) &&
                            <div
                                className={styles["show-more-button"]}
                                onClick={handleShowMore}
                            >
                                {showButtonLoading ?
                                    <CircularProgress className={styles["loading"]}/>
                                    : <h5>Show more</h5>
                                }
                            </div>
                        }
                    </>
                );
            }
        } else if (currentFilter === filters.EVENTS) {
            if (events === undefined || events.content === undefined) {
                return <div className={styles["loading-container"]}><CircularProgress
                    className={styles["progress-circle"]}/></div>;
            } else if (events.content.length === 0) {
                return <div className={styles["no-content"]}><h6>City has no events.</h6></div>;
            } else {
                return (
                    <>
                        <div className={styles["artists-grid-wrapper"]}>
                            <div className={styles["events-grid"]}>
                                {events.content.map((event, index) => {
                                    const eventTime = stringFormatting.formatDateCard(event.startTime)
                                    const artistNames = event.topArtists.slice(0, 2).map((artist, index) => {
                                        return ((index === 0 ? "" : ", ") + artist.name);
                                    })
                                    return (
                                        <div
                                            key={index}
                                            className={styles["event-card"]}
                                            onClick={() => navigate(`/event/${event.id}/${stringFormatting.toSlug(event.name)}`)}
                                        >
                                            <img
                                                src={event?.topArtists[0]?.image ? event.topArtists[0].image : noImage}
                                                alt=""
                                                className={styles["event-image"]}
                                            />
                                            <div>
                                                <h5>{artistNames}</h5>
                                                <h6>{event.venue.name}</h6>
                                                <h6 className={styles["event-date"]}>
                                                    {eventTime.day}, {eventTime.month} {eventTime.dayOfMonth} - {eventTime.time}
                                                </h6>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        {events.totalPages > (eventPageNum + 1) &&
                            <div
                                className={styles["show-more-button"]}
                                onClick={handleShowMore}
                            >
                                {showButtonLoading ?
                                    <CircularProgress className={styles["loading"]}/>
                                    : <h5>Show more</h5>
                                }
                            </div>
                        }
                    </>
                );
            }
        } else if (currentFilter === filters.VENUES) {
            if (venues === undefined || venues.content === undefined) {
                return <div className={styles["loading-container"]}><CircularProgress
                    className={styles["progress-circle"]}/></div>;
            } else if (venues.content.length === 0) {
                return <div className={styles["no-content"]}><h6>City has no venues.</h6></div>;
            } else {
                return (
                    <>
                        <div className={styles["artists-grid-wrapper"]}>
                            <div className={styles["venues-grid"]}>
                                {venues.content.map((venue, index) => (
                                    <div
                                        key={index}
                                        className={styles["venue-card"]}
                                        onClick={() => navigate(`/venue/${venue.id}/${stringFormatting.toSlug(venue.name)}`)}
                                    >
                                        <img src={CityIcon} alt="" className={styles['city-icon']}/>
                                        <div className={styles["text-container"]}>
                                            <h5>{venue.name}</h5>
                                            <h6>{venue.address}</h6>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {venues.totalPages > (venuePageNum + 1) &&
                            <div
                                className={styles["show-more-button"]}
                                onClick={handleShowMore}
                            >
                                {showButtonLoading ?
                                    <CircularProgress className={styles["loading"]}/>
                                    : <h5>Show more</h5>
                                }
                            </div>
                        }
                    </>
                );
            }
        }
    };

    return (
        <div className={styles["general-container"]}>
            <PageHeader
                type={userLoggedIn ? HeaderTypes.BACKABLE : HeaderTypes.LANDING}
                artistInputClicked={() => navigate('/search')}
                editLocationClicked={() => setShowEditCitiesModal(true)}
                settingsIconClicked={() => navigate('/user/settings')}
            />

            <div className={"bottom-right-icons"}>
                <div
                    className={"bottom-right-icon-container"}
                    onClick={() => window.open(window.location.origin + "/feedback-form", "_blank")}
                >
                    <Feedback className={"bottom-right-icon"}/>
                </div>
            </div>

            {errorType &&
                <ErrorModal
                    errorMessage={fetchErrorMessage()}
                    onTryAgain={ErrorTypes.NO_ID_PRESENT ? undefined : handleRetry}
                    onClose={() => navigate("/")}
                    onCloseMessage={"Back to home"}
                />}

            {(showAddArtistsModal && city) &&
                <AddArtistsToCityModal city={city} onClose={() => setShowAddArtistsModal(false)}/>
            }

            {city ? (
                <div>
                    <div className={styles["header-container"]}>
                        <div className={styles["header"]}>
                            <img alt="" src={city?.image || CityIcon} className={styles["city-image"]}/>

                            <div className={styles["header-main-container"]}>
                                <div className={styles["title-info-container"]}>
                                    <h1>{city.name + ", " + (city?.zoneCode || city.countryCode || "")}</h1>
                                    {/* City general info (number artists, events, and venues) */}
                                    <div className={styles["info-container"]}>
                                        <div className={styles["info-item"]}>
                                            <div>
                                                <PersonIcon className={styles["icon"]}/>
                                                <h5>{city.numberOfArtists}</h5>
                                            </div>
                                            <div>
                                                <h6>Local Artists</h6>
                                            </div>
                                        </div>
                                        <div className={styles["info-item"]}>
                                            <div>
                                                <EventIcon className={styles["icon"]}/>
                                                <h5>{city.numberUpcomingEvents}</h5>
                                            </div>
                                            <div>
                                                <h6>Upcoming Events</h6>
                                            </div>
                                        </div>
                                        <div className={styles["info-item"]}>
                                            <div>
                                                <MapsHomeWorkIcon className={styles["icon"]}/>
                                                <h5>{city.numberTotalVenues}</h5>
                                            </div>
                                            <div>
                                                <h6>Local Venues</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles["city-options-container"]}>

                                    {/* Options for a city */}
                                    <div
                                        className={styles["city-options"]}
                                        onClick={() => navigate("")}
                                    >
                                        <Tooltip
                                            title={"Get personalized recommendations"}
                                            placement={"bottom"}
                                            arrow
                                        >
                                            <div
                                                className={styles["icon-container"]}
                                                onClick={() => handleGetPersonalRecs()}
                                            >
                                                <Assessment className={styles["icon"]}/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={"View city in Google Maps"}
                                            placement={"bottom"}
                                            arrow
                                        >
                                            <div
                                                className={styles["icon-container"]}
                                                onClick={() => window.open(`https://www.google.com/maps/place/${city.name},+${city.zoneCode || city.countryCode}`, "_blank")}
                                            >
                                                <Map className={styles["icon"]}/>
                                            </div>
                                        </Tooltip>
                                        <Tooltip
                                            title={city.spotifyPlaylistId ? "View city Spotify page" : "City Spotify page unavailable"}
                                            placement={"bottom"}
                                            arrow
                                        >
                                            <div
                                                className={`${styles["icon-container"]} ${city.spotifyPlaylistId ? styles["spotify-icon"] : styles["disabled-spotify-button"]}`}
                                                onClick={() => {
                                                    if (city.spotifyPlaylistId)
                                                        window.open(`https://open.spotify.com/playlist/${city.spotifyPlaylistId}`, "_blank");
                                                    //Else do nothing
                                                }}
                                            >
                                                <img
                                                    className={styles['icon']}
                                                    src="https://localify-cdn.s3.amazonaws.com/assets/web_assets/Spotify_Primary_Logo_RGB_Black.png"
                                                    alt="Spotify Brand Logo"
                                                    title={`Localify ${city.name} Playlist`}
                                                />
                                            </div>
                                        </Tooltip>
                                    </div>
                                    <div
                                        className={styles["suggest-artists"]}
                                        onClick={() => setShowAddArtistsModal(true)}
                                    >
                                        <GroupAdd className={styles["icon"]}/>
                                        <h6>Suggest Artists</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles["filters-container"]}>
                        {Object.values(filters).map((filter, index) => (
                            <h6
                                key={index}
                                className={`${styles["filters"]} ${currentFilter === filter ? styles.active : ""}`}
                                onClick={() => setCurrentFilter(filter)}
                            >
                                {filter}
                            </h6>
                        ))}
                    </div>
                    {renderContent()}
                </div>
            ) : (
                <div className={styles["loading-container"]}>

                </div>
            )}

            {showEditCitiesModal &&
                <ListOfCitiesModal
                    onClose={() => setShowEditCitiesModal(false)}
                />
            }
        </div>
    )
}

export default CityDetail;
